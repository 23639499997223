@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;500;600&display=swap');
@import url('tailwindcss/base');
@import url('tailwindcss/components');
@import url('tailwindcss/utilities');
@import url('https://fonts.googleapis.com/css2?family=Bakbak+One&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  font-family: 'Inter', sans-serif;
}

.font-mono {
  font-family: 'Source Code Pro', monospace;
}

